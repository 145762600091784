.root {
  background-color: white;
  padding: 20px 10px;
  width: 300px;
}
.title {
  font-size: 18px;
  color: #343434;
  font-weight: normal;
}
