.ql-size .ql-picker-label::before,
.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}

.content__wr {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
}

.image__from__ckeditor {
  width: 100%;
  height: 100%;
}

.ck-editor__editable {
  min-height: 250px;
}
