.content__wr {
  word-wrap: break-word;
}

.editor__data__wrap {
  flex-direction: row;
  display: flex;
  padding: 10px;
  gap: 10px;
}
